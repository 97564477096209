<template>
  <el-dialog
    title="选择文档"
    :close-on-click-modal="false" 
    :visible.sync="visible" @close="closeEvent" width="35%">
    <div class="file-root">
          <p style="line-height: 1;">文件目录：</p>
          <p class="file-path">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item v-for="(item,index) in selectFiles" @click.native="backAppiot(item,index)" :key="index">{{item.name}}</el-breadcrumb-item>
            </el-breadcrumb>
           </p>
       </div>
    <div class="list">
      <div v-for="item in documents" :key="item.id" class="list-item">
           <!-- <el-checkbox :value="selectFiles.length>0?(selectFiles[0].id==item.id):false" @change="((value) => { handleCheckedCitiesChange(item,value) } )"> -->
                <!-- <img :src="thumbIcon(item)" alt="">
                <span class="list-title" @click="clickEvent(item)">
                    {{item.name}}
                </span> -->
                <dl  @click="clickEvent(item)">
                    <dt>
                        <img :src="thumbIcon(item)" alt="">
                    </dt>
                    <dd>
                        <p> {{item.name}}</p>
                        <p> 
                            <span>{{item.ownerName}}</span>
                            <span> | </span>
                            <span>{{ transformTimestamp(item.createDate) }}</span>
                        </p>
                    </dd>
                </dl>
           <!-- </el-checkbox> -->
      </div>
    </div>
   <!-- 分页 -->
   <div class="pagebox">
        <el-pagination background layout="prev, pager, next" :total="total" :page-size="pageSize" @current-change="pageChanged"></el-pagination>
   </div>

    <span slot="footer">

      <div class="btn">
          <el-button type="primary" size="small" @click="onEnterClicked">确定</el-button>
          <el-button type="info"  size="small" v-if="newViewer.depth > 0" @click="doFolderBack" :loading="newViewer.loading">返回</el-button>
      </div>
    </span>

  </el-dialog>
</template>
<script>
import iconFolder from '@/assets/img/folder.png'
import iconDefault from '@/assets/img/icon-def.png'
import iconUnknown from '@/assets/img/icon-unknown.png'
import iconImage from '@/assets/img/icon-picture.png'
import iconOFD from '@/assets/img/icon-ofd.png'
import iconAudio from '@/assets/img/icon-audio.png'
import iconWord from '@/assets/img/icon-word.png'
import iconPpt from '@/assets/img/icon-ppt.png'
import iconPdf from '@/assets/img/icon-pdf.png'
import iconExcl from '@/assets/img/icon-excl.png'
import iconOrg from '@/assets/img/org.png'
import iconDepart from '@/assets/img/depart.png'
import iconMP3 from '@/assets/doc-icon/mp3.png'
import iconMP4 from '@/assets/doc-icon/mp4.png'
import iconZIP from '@/assets/doc-icon/zip.png'


//后缀映射
const suffixIconCache = {
  def: iconDefault,
  folder: iconFolder,
  unknown: iconUnknown,
  org:iconOrg,
  depart:iconDepart,
  png: iconImage,
  jpg: iconImage,
  jpeg: iconImage,
  bmp: iconImage,

  ofd: iconOFD,

  doc: iconWord,
  DOC: iconWord,
  docx: iconWord,

  mp3: iconMP3,
  mp4: iconMP4,
  zip: iconZIP,
  ppt:iconPpt,
  pptx:iconPpt,
  pdf:iconPdf,
  xls:iconExcl,
  xlsx:iconExcl
}
export default {
    props: {
        /**
         * 文档列表
         */
        documents: {
            type: Array,
            default: []
        },
         //已勾选的map集合，作为回显判断使用
        checkedMap: {
            type: Object,
            default: {}
        },
        newViewer: {
            type: Object,
            default: {}
        },
        selectFiles: {
            type: Array,
            default: []
        },
        showCheckbox: {
            type: Boolean,
            default: false
        },
    },
    data(){
        return{
            visible:false,
            vkeys: '', // 标识
            current: 1, // 页数
            size: 20, // 条数
            viewerKey: 'fileSelector', // 获取视图数据
            // newViewervKey: 'myDialogList' // 个人文档 获取 个人文档 数据，弹框展示操作防止冲突
        }
    },
    computed:{
        pageSize() {
            return this.$store.state.doc.viewer[this.viewerKey].query.pageSize || 0
        },
        total() {
            return this.$store.state.doc.viewer[this.viewerKey].paging.total || 0
        },
    },
    watch:{
    },
    mounted(){

    },
    methods:{
        show(vkey){
            this.vkeys = vkey
            this.visible = true
        },
          /**
         * 图标
         */
        thumbIcon(doc){
            let iconKey = "unknown"
            if(doc.documentType==1 &&doc.orgId!=null){
                iconKey='org'
            }else if(doc.documentType == 2 &&doc.orgId!=null){
                iconKey='depart'
            }else{
                if(doc.type == 0){
                    iconKey = "folder"
                }else if(suffixIconCache.hasOwnProperty(doc.suffix)){
                    iconKey = doc.suffix
                }
            }

            return suffixIconCache[iconKey] 
        },
        // 返回
        doFolderBack(){
           this.$emit('doFolderBacks')
        },
        backAppiot(item,index){
            let data = {
                item,index
            }
            this.$emit('backAppiot', data);
        },
        //点击确定
        onEnterClicked(){
            if(this.$parent.isCopy) {
                // this.copyMyDocument(this.selectFiles[0])
            } else if(this.$parent.isCopy === false){
                this.copyOrgDocument(this.selectFiles[this.selectFiles.length-1])
                this.$parent.isCopy = true;
            } else if (this.$parent.isMove === false) {
                this.moveDocument(this.selectFiles[this.selectFiles.length-1])
            } else if (this.$parent.isMove){
                this.copyDocument(this.selectFiles[this.selectFiles.length-1])
            } else if (this.$parent.isSave){
                this.$confirm('确认对所选文件进行归档吗？', "警告", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(res=>{
                    let parentId = this.selectFiles.length>0?this.selectFiles[this.selectFiles.length-1].id:0
                    this.$store.dispatch('tools/historyArchiveList', {
                        convertFileId: this.$parent.isSaveDocId,
                        parentId
                    }).then(res=>{
                        if(res.code == 0) {
                            this.visible = false
                            this.$message.success('归档成功');
                            this.$parent.getHistoryList();
                        } else {
                            this.visible = false
                            this.$message.error(res.message)
                        }
                    })
                }).catch(error=>{
                })
            }
        },
        //复制个人文件到组织文档
        copyMyDocument(file){
            if(file){
                this.$store.dispatch('doc/copyDocument',{
                sourceDocumentId: file.id,
                targetDocumentId: this.$parent.docuInfoId.id
            }).then(res=>{
                if(res==true){
                  this.$message({
                    message: '上传成功',
                    type: 'success'
                 });
                  this.closeEvent()
                  this.visible = false
                }else{
                  this.$message.error(res) 
                  this.visible = false
                }
            }).catch(res=>{
                this.$message.error('上传失败！') 
                this.visible = false
            })
            }  else {
                this.$confirm('您是否将该文件保存到根文件夹下, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                    }).then(() => {
                        this.$store.dispatch('doc/copyDocument',{
                sourceDocumentId: 0,
                targetDocumentId: this.$parent.docuInfoId.id
            }).then(res=>{
                if(res==true){
                  this.$message({
                    message: '上传成功',
                    type: 'success'
                 });
                  this.closeEvent()
                  this.visible = false
                }else{
                  this.$message.error(res) 
                  this.visible = false
                }
            }).catch(res=>{
                this.$message.error('上传失败！') 
                this.visible = false
            })
                }).catch(()=>{

                })
            }
        },
        //复制组织文档到个人
        copyOrgDocument(file){
            if(file){
                this.$store.dispatch('doc/copyDocument',{
                sourceDocumentId: this.$parent.docuInfoId,
                targetDocumentId: file.id
            }).then(res=>{
                if(res==true){
                    this.$message({
                        message: '复制成功',
                        type: 'success'
                    });
                    this.closeEvent()
                    this.visible = false
                }else{
                    this.$message.error(res) 
                    this.visible = false
                }
            }).catch(res=>{
                 this.$message.error('复制失败！') 
                 this.visible = false
            })
            }  else {
                this.$confirm('您是否将该文件保存到根文件夹下, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                    }).then(() => {
                        this.$store.dispatch('doc/copyDocument',{
                sourceDocumentId: this.$parent.docuInfoId,
                targetDocumentId: 0
            }).then(res=>{
                if(res==true){
                    this.$message({
                        message: '复制成功',
                        type: 'success'
                    });
                    this.closeEvent()
                    this.visible = false
                }else{
                    this.$message.error(res) 
                    this.visible = false
                }
            }).catch(res=>{
                 this.$message.error('复制失败！') 
                 this.visible = false
            })
                }).catch(()=>{

                })
            }
        },
         //移动文档
        moveDocument(file){
            if(file){
                this.moveDocumentMode(file)
            }  else {
                this.$confirm('您是否将该文件移动到根文件夹下, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                    }).then(() => {
                    this.moveDocumentMode()
                }).catch(()=>{

                })
            }
        },
        moveDocumentMode(file){
            this.$store.dispatch('doc/moveDocument',{
                sourceDocumentId: this.$parent.docuInfoId ,
                targetDocumentId: file ? file.id : 0
            }).then(res=>{
                if(res==true){
                    this.$message({
                        message: '移动成功',
                        type: 'success'
                    });
                    this.closeEvent()
                    this.visible = false
                }else{
                    this.$message.error(res) 
                    this.visible = false
                }
            }).catch(res=>{
                this.$message.error('移动失败！')
                this.visible = false
            })
        },
        //复制文档
        copyDocument(file){
            if(file){
            this.$store.dispatch('doc/copyDocument',{
                sourceDocumentId: this.$parent.docuInfoId,
                targetDocumentId: file.id
            }).then(res=>{
                if(res==true){
                    this.$message({
                        message: '复制成功',
                        type: 'success'
                    });
                    this.closeEvent()
                    this.visible = false
                }else{
                    this.$message.error(res) 
                    this.visible = false
                }
            }).catch(res=>{
                this.$message.error('复制失败！')
                this.visible = false
            })
            }  else {
                this.$confirm('您是否将该文件复制到根文件夹下, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                    }).then(() => {
                        this.$store.dispatch('doc/copyDocument',{
                sourceDocumentId: this.$parent.docuInfoId,
                targetDocumentId:0
            }).then(res=>{
                if(res==true){
                    this.$message({
                        message: '复制成功',
                        type: 'success'
                    });
                    this.closeEvent()
                    this.visible = false
                }else{
                    this.$message.error(res) 
                    this.visible = false
                }
            }).catch(res=>{
                this.$message.error('复制失败！')
                this.visible = false
            })
                }).catch(()=>{

                })
            }
        },
        // 点击每行
        clickEvent(item){
            if(item.type == 1) {
                return false;
            }
            this.$emit('onItemClicked', 'checked', item)
            this.$emit('onItemClicked', 'click', item)
          
        },
        // 分页
        pageChanged(num) {
            this.$store.dispatch('doc/loadChildren', {
                vkey: this.viewerKey,
                reset: true,
                pageNum: num,
                pageSize: 10,
                docType:'0'
            })
        },
        //时间转换
        transformTimestamp(timestamp){
            let a = new Date(timestamp).getTime();
            const date = new Date(a);
            const Y = date.getFullYear() + '-';
            const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
            const D = (date.getDate() < 10 ? '0'+date.getDate() : date.getDate()) + '  ';
            const h = (date.getHours() < 10 ? '0'+date.getHours() : date.getHours()) + ':';
            const m = (date.getMinutes() <10 ? '0'+date.getMinutes() : date.getMinutes()) + ':' ;
            const s = (date.getSeconds() <10 ? '0'+date.getSeconds() : date.getSeconds() ); // 秒
            const dateString = Y + M + D + h + m + s;
            return dateString;
        },
        // 关闭
        closeEvent(){
           this.$emit('closeEvent')
        }

    }
}
</script>
<style lang='less' scoped>
/deep/ .el-dialog__footer {
    display: flex;
    text-align: left;
    width: 100%;
    span {
        width: 100%;
        .btn {
            width: 100%;
            display: flex;
            justify-content: flex-end;
        }
    }

}
.file-root{
    display: flex;
    align-items: center;

    .file-path {
        .el-breadcrumb {
            display: flex;
            .el-breadcrumb__item {
                width: auto !important;
                cursor: pointer;
            }
        }
    }
}
/deep/ .el-dialog__body {
    padding: 10px 20px 15px 20px !important;
}
/deep/ .el-checkbox__input {
    margin-bottom: 14px;
}
.list{
    height: 350px;
    overflow-y: auto;
    .list-item {
        dl {
            display: flex;
            padding: 0;
            margin: 0;
            cursor: pointer;
            dt {
                margin-top: 15px;
                img {
                    width: 30px;
                    height: 30px;
                }
            }
            dd {
                padding: 0;
                margin: 0;
                padding-left: 20px;
                p:nth-child(1){
                    height: 0px;
                    padding-bottom: 6px;
                }
                p:nth-child(2){
                    color: #999;
                }
            }
        }
    }
}
</style>
